<template>
  <div style="height: 100%; background-color: #ffffff">
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="
        type === 'view'
          ? '安全日志查看'
          : type === 'add'
          ? '安全日志新增'
          : '安全日志编辑'
      "
      @head-save="headSave(1)"
      @head-submit="headSave(2)"
      @head-cancel="headCancel"
    ></head-layout>
    <avue-form
      :option="formOptions"
      ref="formLayout"
      v-model="dataForm"
      style="padding: 10px"
    >
      <template slot="logCode">
        <el-input
          v-model="dataForm.logCode"
          maxlength="64"
          show-word-limit
          :disabled="
            this.type == 'view' ||
            this.type == 'edit' ||
            dataForm.isAutomatic ||
            dataForm.id
          "
        >
          <template slot="append">
            <el-switch
              v-model="dataForm.isAutomatic"
              active-text="自动生成"
              @change="handleSwidth"
              :disabled="type == 'view' || type == 'edit' || dataForm.id"
            ></el-switch>
          </template>
        </el-input>
      </template>
    </avue-form>
    <div class="shuttleBackBox" v-if="dataForm.id">
      <el-tabs
        type="border-card"
        v-model="activeNameTab"
        @tab-click="handleClick"
      >
        <el-tab-pane label="附件" name="logFiles">
          <div class="“logFiles”" v-if="activeNameTab == 'logFiles'">
            <head-layout
              v-if="type != 'view'"
              head-title="资料清单"
              :head-btn-options="
                this.status == 2 || this.status == 1 ? fileBtnOptions : []
              "
              @headImport="headImport"
            >
              <el-button
                v-if="this.status == 2 || this.status == 1 ? true : false"
                @click="openKnowledgeSearchDialog()"
                size="mini"
                slot="right"
                >知识库选择</el-button
              >
            </head-layout>
            <el-upload
              :disabled="this.status != 1"
              :before-remove="beforeRemove"
              :on-remove="handleRemove"
              :on-preview="handlePreview"
              :file-list="uploadFileList"
              class="upload-demo"
            >
            </el-upload>
          </div>
        </el-tab-pane>
        <el-tab-pane label="问题清单" name="logProblem">
          <head-layout
            v-if="tabype != 'view'"
            head-title="问题清单"
            :head-btn-options="this.status == 1 ? problemBtnOptions : []"
            @head-add="dangerAdd"
            @head-remove="hiddenDangerRemove"
          >
            <el-button
              v-if="this.status == 1 ? true : false"
              @click="hiddenDangerSelect()"
              size="mini"
              slot="right"
              >选择隐患</el-button
            >
          </head-layout>
          <grid-layout
            ref="gridLayOut"
            :table-options="hiddenDangerOption"
            :table-data="hiddenDangerTableData"
            :table-loading="hiddenDangerLoading"
            @gird-handle-select-click="selectionHiddenDangerChange"
            :grid-row-btn="gridRowBtn"
            @row-del="rowDel"
            @row-edit="rowEdit"
          >
          </grid-layout>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-upload
      v-show="hideUpload"
      accept=".doc,.docx,.xlsx,.xls,.zip,.jpg,.png,.rar,.ppt,.pptx"
      class="upload-demo"
      action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km"
      :show-file-list="false"
      :on-success="handleSuccess"
      ref="uploadRef"
      :headers="headers"
    ></el-upload>
    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFilesDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFilesDialog = false"
          >取消</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      top="9vh"
      title="知识库选择"
      :visible.sync="showKnowledgeSearchDialog"
      width="80%"
    >
      <KnowledgeSearchDialog
        ref="knowledgeSearchDialog"
        @close-dialog="closeKnowledgeSearchDialog"
        @select-data="selectFile"
        :confirmLoading="confirmLoading"
      ></KnowledgeSearchDialog>
    </el-dialog>
    <el-dialog
      title="问题"
      :modal="false"
      :modal-append-to-body="false"
      :visible.sync="dangerAddDialog"
      width="60%"
    >
      <el-form
        ref="dangerAddForm"
        :model="dangerAddData"
        label-width="150px"
        :rules="rules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="问题描述:" label-width="150px" prop="content">
              <el-input
                v-model="dangerAddData.content"
                type="textarea"
                placeholder="请输入问题描述"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="报告人:"
              label-width="150px"
              prop="reportUserName"
            >
              <el-input
                v-model="dangerAddData.reportUserName"
                @focus="headDangerAdd()"
                placeholder="请选择报告人"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="时间:" label-width="150px" prop="reportTime">
              <el-date-picker
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="dangerAddData.reportTime"
                placeholder="请选择时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button size="small" @click="dangerAddDialog = false">{{
          $t(`cip.cmn.btn.celBtn`)
        }}</el-button>
        <el-button size="small" type="primary" @click="dangerAddSave">{{
          $t(`cip.cmn.btn.defBtn`)
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="人员选择"
      :visible.sync="deptShow"
      v-if="deptShow"
      width="80%"
      top="8vh"
    >
      <UserDetpDialog
        ref="UserDetpDialog"
        :dept-category="[2, 5]"
        @select-data="selectData"
      ></UserDetpDialog>
    </el-dialog>

    <!-- 隐患清单选择 -->
    <el-dialog :visible.sync="hiddenDangerDialog" title="选择隐患" width="80%">
      <riskItemViewCheckList ref="riskItemViewCheckList" multipleType @changeData="changeHidden" />
    </el-dialog>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { dateFormat } from "@/util/date";
import { getToken } from "@/util/auth";
import website from "@/config/website";
import { mapGetters } from "vuex";
import riskItemViewCheckList from './component/riskItemViewCheckList.vue'
import exForm from "@/views/plugin/workflow/mixins/ex-form";

import {
  LogList,
  getLogDetail,
  addLog,
  getCode,
  LogFilesList,
  addLogFiles,
  logFilesRemove,
  LogProblemList,
  addLogProblem,
  logProblemRemove,
  LogGetLastData,
  addLogProblemBatchSubmit,
  LogUpdateProcessId,
  getFormByProcessDefKey
} from "@/api/siteManagement/securityLog";
import { getList, getDept } from "@/api/system/dept";
import KnowledgeSearchDialog from "@/views/components/knowledge/KnowledgeSearchDialog.vue";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";
import {detail, processList as getProcessList} from "@/api/plugin/workflow/process";
import {getTodayWeather} from "@/api/onlineDev/portal"
import AMapLoader from "@amap/amap-jsapi-loader"; //引入AMapLoader

export default {
  components: {
    FormLayout,
    HeadLayout,
    GridLayout,
    UserDetpDialog,
    KnowledgeSearchDialog,
    riskItemViewCheckList
  },
  data() {
    return {
      prjData: [],
      type: "view",
      activeNameTab: "logFiles",
      dataForm: {
        isAutomatic: true,
      },
      fileUrl: "",
      hideUpload: false, //附件 上传
      uploadFileList: [], //附件 资料清单
      showFilesDialog: false, //附件 预览
      showKnowledgeSearchDialog: false, //知识库选择
      dangerAddDialog: false,
      hiddenDangerDialog: false, //新增隐患弹窗
      deptShow: false, //新增隐患弹窗 选择报告人弹窗
      hiddenDangerLoading: false,
      dangerAddData: {},
      rules: {
        content: [
          {
            required: true,
            message: this.$t("cip.cmn.rule.inputWarning"),
            trigger: "blur",
          }
        ]
      },
      multipleSelection: [],
      formProcess: {
        id: '',
        processId: '',
        data: {},
      },
      confirmLoading: false, // 知识库选择按钮限制
    };
  },

  mixins: [exForm],

  computed: {
    ...mapGetters(["userInfo"]),
    headers: function () {
      return { "Sinoma-Auth": getToken() };
    },
    headBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.type)) {
        result.push({
          label: "保存",
          emit: "head-save",
          btnOptType: "save",
        });
        result.push({
          label: "提交",
          emit: "head-submit",
        });
      }
      result.push({
        label: "取消",
        emit: "head-cancel",
      });
      return result;
    },
    fileBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.type)) {
        result.push({
          label: "上传",
          emit: "headImport",
          btnOptType: "headImport",
        });
        return result;
      }
    },
    problemBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.type)) {
        result.push({
          label: "新增",
          emit: "head-add",
          btnOptType: "add",
        });
        result.push({
          label: "移除",
          emit: "head-remove",
          btnOptType: "remove",
        });
        return result;
      }
    },
    formOptions() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 100,
        column: [
          {
            label: "日志编号",
            prop: "logCode",
            slot: true,
            span: 8,
            labelWidth: 120,
            maxlength: 64,
            showWordLimit: true,
            disabled: !["add"].includes(this.type),
            rules: [
              {
                required: true,
                message:
                  this.$t("cip.cmn.rule.inputWarning") + "编号或由系统自动生成",
                trigger: ["blur", "change"],
              },
            ],
          },
          {
            label: "标题",
            prop: "title",
            span: 16,
            labelWidth: 120,
            maxlength: 255,
            showWordLimit: true,
            disabled: !["add", "edit"].includes(this.type),
            rules: [
              {
                required: true,
                validator: this.$t("cip.cmn.rule.inputWarning") + "标题",
                trigger: "blur",
              },
            ],
          },
          {
            label: "日志内容",
            prop: "content",
            type: "textarea",
            span: 24,
            labelWidth: 120,
            disabled: !["add", "edit"].includes(this.type),
            maxlength: 255,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + "日志内容",
                trigger: "blur",
              },
            ],
          },
          {
            label: "天气",
            prop: "weather",
            span: 8,
            labelWidth: 120,
            maxlength: 50,
            showWordLimit: true,
            disabled: !["add", "edit"].includes(this.type),
            rules: [
              {
                required: false,
                message: this.$t("cip.cmn.rule.inputWarning") + "天气",
                trigger: "blur",
              },
            ],
          },

          {
            label: "专业",
            prop: "profession",
            span: 8,
            labelWidth: 120,
            type: "select",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=LOG_PROFESSION_TYPE",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            disabled: !["edit", "add"].includes(this.type),
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + "专业",
                trigger: "change",
              },
            ],
          },
          {
            label: "日期",
            prop: "logDate",
            span: 8,
            labelWidth: 120,
            type: "date",
            format: "yyyy-MM-dd", // 设置日期格式
            valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
            disabled: !["edit", "add"].includes(this.type),
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.selectWarning") + "设置时间",
                trigger: "change",
              },
            ],
          },
          {
            label: "项目编号",
            prop: "projectCode",
            span: 8,
            labelWidth: 120,
            disabled: true,
          },
          {
            label: "项目名称",
            prop: "projectName",
            span: 16,
            labelWidth: 120,
            disabled: true,
          },
          {
            label: "备注",
            prop: "remark",
            span: 24,
            labelWidth: 120,
            type: "textarea",
            showWordLimit: true,
            maxlength: 255,
            disabled: !["edit", "add"].includes(this.type),
          },
        ],
      };
    },
    hiddenDangerOption () {
      return {
        addBtn: false,
        editBtn: false,
        rowKey: "id",
        menu: this.type!='view',
        menuFixed: "right", //操作列冻结
        menuWidth: 120,
        column: [
          {
            label: "问题描述",
            prop: "content",
            align: "left",
            overHidden: true,
            width: 300,
          },
          {
            label: "关联隐患编号",
            prop: "relatedDocCode",
            align: "center",
            overHidden: true,
            width: 180
          },
          {
            label: "报告人",
            prop: "reportUserName",
            align: "center",
            overHidden: true,
          },
          {
            label: "报告时间",
            prop: "reportTime",
            align: "center",
            width: 180,
            overHidden: true,
          },
        ],
      }
    },

    gridRowBtn () {
      let btnList = []
      if(this.type != 'view' && this.status==1) {
        btnList = [
          {
            label: this.$t(`cip.cmn.btn.editBtn`),
            emit: "row-edit",
            type: "button",
            icon: "",
          },
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "row-del",
            type: "button",
            icon: "",
          }
        ]
      }
      return btnList
    }

  },
  created() {
    let { id, type, status } = this.$route.query;
    this.type = type;
    this.status = status;
    if (this.type !== "add") {
      this.dataForm.id = id;
      this.initData();
      this.getFile();
    }
    if (this.type == "add") {
      this.handleSwidth(); //自动生成编号
      this.getWeather()
      this.dataForm.logDate = dateFormat(new Date(), "yyyy-MM-dd"); //日期
      this.dataForm.title = dateFormat(new Date(), "yyyy年MM月dd日安全日志"); //title
      this.dataForm.profession = "安环"; //专业默认安环
      this.dataForm.nickName = this.userInfo.nick_name
      this.dataForm.userId = this.userInfo.user_id
      this.dataForm.userName = this.userInfo.real_name
      this.dataForm.organizationId = this.userInfo.dept_id
      this.dataForm.organizationCode = this.userInfo.dept_code
      LogGetLastData({userId: this.userInfo.user_id, organizationId: this.userInfo.dept_id}).then(res=>{
        let lastData = res.data.data
        if(lastData && lastData.id){
          this.dataForm.title = lastData.title
          this.dataForm.content = lastData.content
        }
      })
      this.initProjectCodeAndName(); //项目编号，项目名称

    }
    if (this.type == "view" || this.type == "edit") {
      this.dataForm.isAutomatic = false; //控制自动生成按钮左右
    }
  },
  mounted() {
    // if (this.type == "view") {
    //   this.$refs.formLayout.allDisabled = true;
    // }
    getProcessList(1, 99, {}).then((res) => {
      let process = res.data.data.records.filter(
        (item) => item.key == 'log_record'
      )[0];
      // this.processId = process.id;
      this.formProcess.processId = process.id
    });
  },

  methods: {
    initData() {
      getLogDetail(this.dataForm.id).then((res) => {
        const { data } = res.data;
        this.dataForm = data;
        // if(this.type !== 'view'){
        //   this.getWeather()
        // }
        // this.dataForm.status = this.dataForm.status;
      });
    },
    //日志编号自动生成
    handleSwidth() {
      if (this.dataForm.isAutomatic) {
        getCode().then((res) => {
          this.dataForm.logCode = res.data.data;
        });
      } else {
        this.dataForm.logCode = "";
      }
    },
    //项目编号和项目名称
    initProjectCodeAndName() {
      getDept(this.userInfo.dept_id).then((res) => {
        this.prjData = res.data.data;
        this.dataForm.projectId = this.prjData.id;
        this.dataForm.projectCode = this.prjData.deptCode;
        const cc = JSON.parse(this.prjData.deptName);
        this.dataForm.projectName = cc.zh;
      });
    },
    //保存
    headSave(x) {
      this.dataForm = this.$refs.formLayout.form;
      // this.dataForm.nickName = this.userInfo.nick_name; //编制人
      // this.dataForm.organizationCode = this.userInfo.dept_name; //所在部门
      if (x == 1) {
        this.dataForm.status = 1;
      } else if (x == 2) {
        this.dataForm.status = 2;
      }
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          addLog(this.dataForm)
            .then((res) => {
              const { msg, data } = res.data;
              if (res.data.code === 200 && this.dataForm.status == 1) {
                this.$message.success(
                  this.$t("cip.cmn.msg.success.operateSuccess")
                );
                Object.assign(this.dataForm, data);
                // this.type = "edit";
                // this.onLoad(this.page);
                if(this.type == 'add'){
                  this.$router.replace({
                    path: `/siteManagement/securityLog/edit`,
                    query: {
                      id: data,
                      logCode: this.dataForm.logCode,
                      type: "edit",
                      status: this.dataForm.status,
                    },
                  });
                } else {
                  this.onLoad(this.page)
                }
              } else if (res.data.code === 200 && this.dataForm.status == 2) {
                this.$message.success(
                  this.$t("cip.cmn.msg.success.operateSuccess")
                );
                this.formProcess.id = this.dataForm.id
                this.formProcess.data = {...this.dataForm}
                this.formProcess.deptId = this.userInfo.dept_id
                this.formProcess.processBusinessName = this.dataForm.title
                this.handleStartProcess5(true).then(done => {
                  // 更新业务数据流程任务id
                  LogUpdateProcessId({...this.dataForm,processInstanceId: done.data.data}).then(resUp => {
                    this.$router.$avueRouter.closeTag();
                    this.headCancel()
                  })
                  done()
                })
              }
            })
            .finally(() => {
              this.$loading().close();
              this.$refs.formLayout.$refs.form.allDisabled = false;
            });
        }
      });
    },
    // 返回
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

    // 换tab
    handleClick() {
      this.selectionList = [];
      if (!this.dataForm.id) return;
      if (this.activeNameTab == "logFiles") {
        this.getFile();
      } else if (this.activeNameTab == "logProblem") {
        this.hiddenDangerOnload();
      }
    },

    //附件
    getFile() {
      LogFilesList(this.dataForm.id).then((res) => {
        this.uploadFileList = res.data.data.map((item) => {
          return {
            // logId: this.dataForm.id, //日志id
            name: item.attachmentName, //附件名称
            link: item.attachmentUrl, //附件地址
            id: item.id
            // attachmentType: "." + item.fileSuffixType, //附件类型
          };
        });
      });
    },
    selectFile(data) {
      this.confirmLoading = true
      let file = data.map((item) => {
        // let file = item.attachList[0] || {};
        return {
          logId: this.dataForm.id, //日志id
          attachmentName: item.fileName, //附件名称
          attachmentUrl: item.fileCover, //附件地址
          attachmentType: "." + item.fileSuffixType, //附件类型
        };
      });
      this.addFile(file);
      this.showKnowledgeSearchDialog = false;
    },
    // 新增上传文件
    handleSuccess(response, file, fileList) {
      let { name, link, originalName } = response.data;
      const fileType = link.substring(link.lastIndexOf(".") + 1);
      this.addFile([
        {
          logId: this.dataForm.id, //日志id
          attachmentName: originalName, //附件名称
          attachmentUrl: link, //附件地址
          attachmentType: "." + fileType, //附件类型
        },
      ]);
    },
    // 删除上传文件
    handleRemove(file, fileList) {
      logFilesRemove(file.id).then((res) => {
        this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
        this.getFile();
      });
    },
    addFile(list) {
      addLogFiles(list).then((res) => {
        this.confirmLoading = false
        this.$message.success(res.data.msg);
        this.getFile();
      });
    },
    handlePreview(item) {
      this.fileUrl =
        website.kkfileUrl +
        "?url=" +
        encodeURIComponent(Base64.encode(item.link));
      this.showFilesDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },

    //打开 知识库选择 弹窗
    openKnowledgeSearchDialog() {
      this.showKnowledgeSearchDialog = true;
    },
    //关闭 知识库选择 弹窗
    closeKnowledgeSearchDialog() {
      this.showKnowledgeSearchDialog = false;
    },
    //资料清单 上传
    headImport() {
      this.$refs["uploadRef"].$children[0].$refs.input.click();
    },
    //隐患清单 加载
    hiddenDangerOnload() {
      this.hiddenDangerLoading = true;
      LogProblemList({ logId: this.dataForm.id }).then((res) => {
        const data = res.data.data;
        this.hiddenDangerTableData = data;
        this.hiddenDangerLoading = false;
      });
    },
    //隐患清单 选择 弹窗
    hiddenDangerSelect() {
      this.hiddenDangerDialog = true;
    },
    //隐患清单 选择 弹窗
    dangerAdd() {
      this.dangerAddData = {
        reportUserId: this.userInfo.user_id,
        reportUserName: this.userInfo.real_name,
        reportTime: dateFormat(new Date(), "yyyy-MM-dd hh:mm:ss"),
        logId: this.dataForm.id
      };
      this.dangerAddDialog = true;
    },
    rowEdit(row) {
      this.dangerAddDialog = true;
      this.dangerAddData = row;
    },
    //隐患清单 新增 弹窗 选择 报告人
    headDangerAdd() {
      this.deptShow = true;
    },
    selectData(row) {
      this.$set(this.dangerAddData, "reportUserId", row.id);
      this.$set(this.dangerAddData, "reportUserName", row.realName);
      this.deptShow = false;
    },

    // 选择隐患
    changeHidden (hiddenData,selectType) {
      const logId = this.dataForm.id
      if(hiddenData) {
        let arr = hiddenData.map(val=>{
          return {
            relatedDocId: val.id,
            relatedDocCode: val.hdCode,
            content: val.hdDesc,
            logId,
            reportUserName: val.reportUserName,
            reportUserId: val.reportUserId,
            reportTime: dateFormat(new Date(), "yyyy-MM-dd hh:mm:ss")
          }
        })
        addLogProblemBatchSubmit(arr).then(res=>{
          this.$message.success("操作成功")
          this.hiddenDangerDialog = false
          this.hiddenDangerOnload()
        })
      } else {
        this.hiddenDangerDialog = false
      }
    },

    rowDel (row) {
      this.rowRemove (row.id)
    },

    rowRemove (ids) {
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          logProblemRemove(ids).then()
          .then(() => {
            this.hiddenDangerOnload(this.page)
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          });
        })
    },

    dangerAddSave () {
      this.$refs.dangerAddForm.validate((valid) => {
        if(valid) {
          this.dangerAddData.logId = this.dataForm.id
          addLogProblem(this.dangerAddData).then(()=>{
            this.$message.success("操作成功")
            this.dangerAddDialog = false
            this.hiddenDangerOnload()
          })
        }
      })
    },

    selectionHiddenDangerChange (list) {
      this.multipleSelection = list
    },

    hiddenDangerRemove () {
      let ids = this.multipleSelection.map(val=>val.id).toString()
      if(ids){
        this.rowRemove(ids)
      } else {
        this.$message.warning('请先选择数据')
      }
    },

    handleProcess () {
      this.handleStartProcess5(true).then(done => {
        // 更新业务数据流程任务id
        trainingPlanIdUpdate({taskId: done.data.data, id: res.data.data}).then(resUp => {
          this.$router.$avueRouter.closeTag();
          this.$router.push(
            "/business/safetyenment/train/plan"
          );
        })
        done()
      })
    },

    getWeather () {
      let that = this
      AMap.plugin('AMap.CitySearch', function() {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function(status, result) {
            if (status === 'complete' && result.info) {
              getTodayWeather(result.city).then(res=>{
                if(res.data.code == 200){
                  let data = res.data.data ? res.data.data.results[0].now : {}
                  that.dataForm.weather = data ? `${data.text}，${data.temperature ? data.temperature+'℃' : ''}` : ''
                }
              })
            }
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.formContentBox {
  // padding: 12px;
  height: 100%;
}

.formMain {
  width: 100%;
  height: 100%;
}

.shuttleBackBox {
  width: 100%;
  height: calc(100% - 225px);

  .el-tabs {
    width: 100%;
  }

  .upload-demo {
    background-color: #ffffff;
    // padding-left: 10px;
  }

  .logFiles {
    width: 100%;
    height: 400px;
  }
}
</style>
