var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%", "background-color": "#ffffff" } },
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title":
            _vm.type === "view"
              ? "安全日志查看"
              : _vm.type === "add"
              ? "安全日志新增"
              : "安全日志编辑",
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave(1)
          },
          "head-submit": function ($event) {
            return _vm.headSave(2)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "avue-form",
        {
          ref: "formLayout",
          staticStyle: { padding: "10px" },
          attrs: { option: _vm.formOptions },
          model: {
            value: _vm.dataForm,
            callback: function ($$v) {
              _vm.dataForm = $$v
            },
            expression: "dataForm",
          },
        },
        [
          _c(
            "template",
            { slot: "logCode" },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    maxlength: "64",
                    "show-word-limit": "",
                    disabled:
                      this.type == "view" ||
                      this.type == "edit" ||
                      _vm.dataForm.isAutomatic ||
                      _vm.dataForm.id,
                  },
                  model: {
                    value: _vm.dataForm.logCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "logCode", $$v)
                    },
                    expression: "dataForm.logCode",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "append" },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-text": "自动生成",
                          disabled:
                            _vm.type == "view" ||
                            _vm.type == "edit" ||
                            _vm.dataForm.id,
                        },
                        on: { change: _vm.handleSwidth },
                        model: {
                          value: _vm.dataForm.isAutomatic,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "isAutomatic", $$v)
                          },
                          expression: "dataForm.isAutomatic",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm.dataForm.id
        ? _c(
            "div",
            { staticClass: "shuttleBackBox" },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "border-card" },
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeNameTab,
                    callback: function ($$v) {
                      _vm.activeNameTab = $$v
                    },
                    expression: "activeNameTab",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "附件", name: "logFiles" } },
                    [
                      _vm.activeNameTab == "logFiles"
                        ? _c(
                            "div",
                            { staticClass: "“logFiles”" },
                            [
                              _vm.type != "view"
                                ? _c(
                                    "head-layout",
                                    {
                                      attrs: {
                                        "head-title": "资料清单",
                                        "head-btn-options":
                                          this.status == 2 || this.status == 1
                                            ? _vm.fileBtnOptions
                                            : [],
                                      },
                                      on: { headImport: _vm.headImport },
                                    },
                                    [
                                      (
                                        this.status == 2 || this.status == 1
                                          ? true
                                          : false
                                      )
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                slot: "right",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openKnowledgeSearchDialog()
                                                },
                                              },
                                              slot: "right",
                                            },
                                            [_vm._v("知识库选择")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("el-upload", {
                                staticClass: "upload-demo",
                                attrs: {
                                  disabled: this.status != 1,
                                  "before-remove": _vm.beforeRemove,
                                  "on-remove": _vm.handleRemove,
                                  "on-preview": _vm.handlePreview,
                                  "file-list": _vm.uploadFileList,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "问题清单", name: "logProblem" } },
                    [
                      _vm.tabype != "view"
                        ? _c(
                            "head-layout",
                            {
                              attrs: {
                                "head-title": "问题清单",
                                "head-btn-options":
                                  this.status == 1 ? _vm.problemBtnOptions : [],
                              },
                              on: {
                                "head-add": _vm.dangerAdd,
                                "head-remove": _vm.hiddenDangerRemove,
                              },
                            },
                            [
                              (this.status == 1 ? true : false)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { slot: "right", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.hiddenDangerSelect()
                                        },
                                      },
                                      slot: "right",
                                    },
                                    [_vm._v("选择隐患")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("grid-layout", {
                        ref: "gridLayOut",
                        attrs: {
                          "table-options": _vm.hiddenDangerOption,
                          "table-data": _vm.hiddenDangerTableData,
                          "table-loading": _vm.hiddenDangerLoading,
                          "grid-row-btn": _vm.gridRowBtn,
                        },
                        on: {
                          "gird-handle-select-click":
                            _vm.selectionHiddenDangerChange,
                          "row-del": _vm.rowDel,
                          "row-edit": _vm.rowEdit,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("el-upload", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hideUpload,
            expression: "hideUpload",
          },
        ],
        ref: "uploadRef",
        staticClass: "upload-demo",
        attrs: {
          accept: ".doc,.docx,.xlsx,.xls,.zip,.jpg,.png,.rar,.ppt,.pptx",
          action:
            "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km",
          "show-file-list": false,
          "on-success": _vm.handleSuccess,
          headers: _vm.headers,
        },
      }),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "附件预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFilesDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFilesDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFilesDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            top: "9vh",
            title: "知识库选择",
            visible: _vm.showKnowledgeSearchDialog,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showKnowledgeSearchDialog = $event
            },
          },
        },
        [
          _c("KnowledgeSearchDialog", {
            ref: "knowledgeSearchDialog",
            attrs: { confirmLoading: _vm.confirmLoading },
            on: {
              "close-dialog": _vm.closeKnowledgeSearchDialog,
              "select-data": _vm.selectFile,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "问题",
            modal: false,
            "modal-append-to-body": false,
            visible: _vm.dangerAddDialog,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dangerAddDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dangerAddForm",
              attrs: {
                model: _vm.dangerAddData,
                "label-width": "150px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "问题描述:",
                            "label-width": "150px",
                            prop: "content",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入问题描述",
                            },
                            model: {
                              value: _vm.dangerAddData.content,
                              callback: function ($$v) {
                                _vm.$set(_vm.dangerAddData, "content", $$v)
                              },
                              expression: "dangerAddData.content",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "报告人:",
                            "label-width": "150px",
                            prop: "reportUserName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请选择报告人" },
                            on: {
                              focus: function ($event) {
                                return _vm.headDangerAdd()
                              },
                            },
                            model: {
                              value: _vm.dangerAddData.reportUserName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dangerAddData,
                                  "reportUserName",
                                  $$v
                                )
                              },
                              expression: "dangerAddData.reportUserName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "时间:",
                            "label-width": "150px",
                            prop: "reportTime",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              placeholder: "请选择时间",
                            },
                            model: {
                              value: _vm.dangerAddData.reportTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.dangerAddData, "reportTime", $$v)
                              },
                              expression: "dangerAddData.reportTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dangerAddDialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t(`cip.cmn.btn.celBtn`)))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.dangerAddSave },
                },
                [_vm._v(_vm._s(_vm.$t(`cip.cmn.btn.defBtn`)))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.deptShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "人员选择",
                visible: _vm.deptShow,
                width: "80%",
                top: "8vh",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.deptShow = $event
                },
              },
            },
            [
              _c("UserDetpDialog", {
                ref: "UserDetpDialog",
                attrs: { "dept-category": [2, 5] },
                on: { "select-data": _vm.selectData },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.hiddenDangerDialog,
            title: "选择隐患",
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.hiddenDangerDialog = $event
            },
          },
        },
        [
          _c("riskItemViewCheckList", {
            ref: "riskItemViewCheckList",
            attrs: { multipleType: "" },
            on: { changeData: _vm.changeHidden },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }