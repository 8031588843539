import {HD_SOURCE_TYPE} from "@/api/riskitemView/evaluate";

export default {
  data(){
    return {
      hdLevel:{},
      hdLevelText:[],
      hdLevelIcon:{
        '1':'success',
        '2':'default',
        '3':'warning',
        '4':'danger',
      },
      hdLevelColor:{
        '1':'#67c23a',
        '2':'#0d7fea',
        '3':'#e6a23c',
        '4':'#C5211B',
      },
      hdStatusIcon:{
        'prepare':'#cecece',
        'unassess':'#e99d42',
        'canceled':'#bd3124',
        'unrectify':'#e99d42',
        'unaccept':'#e99d42',
        'finished':'#4095e5',
        'revoke':'#cecece'
      },
    }
  },
  async created() {
    await  HD_SOURCE_TYPE('hd_level').then(res=>{
      this.hd_level=res.data.data
    })
    this.hdLevelText=this.hd_level.map(item =>item.dictValue)
    this.hdLevel = Object.fromEntries(this.hd_level.map(item => [item.dictKey, item.dictValue]));
  }
}
